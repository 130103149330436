<template>
  <div>
    <v-form v-model="valid" ref="form">
      <div class="tway-header-2 mb-6">
        Mejoras

        <v-tooltip bottom max-width="21em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span>Describe las mejoras cuantitativas</span>
          <br />
          <span>(ej: cantidad, tiempo) y cualitativas</span>
          <br />
          <span>(ej: calidad, eficiencia) para tu proyecto.</span>
        </v-tooltip>
      </div>
      <div class="input-label ml-2">Cuantitativas</div>
      <v-textarea
        v-model="formData.quantitativeImprovements"
        :rules="formData.quantitativeImprovements ? [validateMaxLength(250)] : []"
        counter
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Describe las mejoras cuantitativas"
        outlined
        no-resize
      />
      <div class="input-label ml-2">Cualitativas</div>
      <v-textarea
        v-model="formData.qualitativeImprovements"
        :rules="formData.qualitativeImprovements ? [validateMaxLength(250)] : []"
        counter
        :counter-value="formatCounter(255)"
        class="mb-3 roboto"
        name="input-7-1"
        placeholder="Describe las mejoras cualitativas"
        outlined
        no-resize
      />
      <v-divider class="mb-4" />
      <div class="tway-header-2 mb-6">Indicadores de éxito</div>
      <div class="input-label ml-2">Principales Indicadores de éxito</div>
      <v-select
        return-object
        v-model="formData.evaluationCriteria"
        multiple
        :rules="[validateMaxItems(3)]"
        :items="evaluateCrits"
        outlined
        item-text="name"
        class="mb-4 roboto"
        placeholder="Selecciona uno o varios Criterios"
        no-resize
      />
      <v-divider class="mb-4" />
      <div class="tway-header-2 mb-6">Presupuesto Estimado</div>
      <v-text-field
        v-model="formData.estimatedBudged.value"
        outlined
        class="mb-4 roboto"
        type="number"
        :placeholder="getCompany.countryCode == 'CL' ? 'Escribe un monto (UF)' : 'Escribe un monto'"
        no-resize
      />
      <v-divider class="mb-4" />
      <div class="tway-header-2 mb-6">Duración Estimada</div>
      <div class="input-label ml-2">Período estimado</div>
      <v-select
        return-object
        v-model="formData.period"
        :rules="[(value) => (value ? true : `Campo período estimado es requerido`)]"
        :items="periods"
        outlined
        item-text="name"
        class="mb-4 roboto"
        placeholder="Selecciona uno de los periodos estimados"
        no-resize
      />
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidatorsService from "@/services/validatorsService";
import ImpactsJSON from "@/utils/impacts.json";
import ImprovementsJSON from "@/utils/improvements.json";
import PeriodsJSON from "@/utils/basicPeriods.json";

export default {
  name: "Improvements",
  mounted() {
    this.setCompanyProfile();
    if (this.stepIsComplete(this.stepIndex)) {
      let stepData = this.getStep(this.stepIndex);
      this.formData = { ...stepData.data };
    } else {
      this.setInProgress(this.stepIndex);
    }
  },
  updated() {
    this.setComplete(this.stepIndex);
    this.setStepData({
      index: this.stepIndex,
      data: { ...this.formData },
    });
    if (this.getCompany.countryCode == "CL") {
      this.formData.estimatedBudged.currency = "CLF";
    } else {
      this.formData.estimatedBudged.currency = null;
    }
  },
  data() {
    return {
      stepIndex: 1,
      valid: false,

      evaluateCrits: ImpactsJSON,
      optMock: ImprovementsJSON,
      periods: PeriodsJSON,
      formData: {
        period: null,
        quantitativeImprovements: "",
        qualitativeImprovements: "",
        evaluationCriteria: null,
        estimatedBudged: {
          value: null,
          currency: "",
        },
      },
    };
  },
  methods: {
    ...mapActions("createProject", ["setInProgress", "setComplete", "setIncomplete", "setStepData", "resetStep"]),
    ...mapActions("UserStore", ["setCompanyProfile"]),
    formatCounter: (max) => (text) => text ? max - text.length : 255,
    required: (propertyType) => ValidatorsService.required(propertyType),
    requiredYear: (propertyType) => ValidatorsService.requiredYear(propertyType),
    validateMaxLength: (max) => {
      return ValidatorsService.validateMaxLength(max);
    },
    validateMaxItems: (max) => ValidatorsService.validateMaxItems(max),
  },
  computed: {
    ...mapGetters({
      stepIsComplete: "createProject/getStepIsComplete",
      getStep: "createProject/getStep",
    }),
    ...mapGetters("UserStore", ["getCompany"]),
    years() {
      let years = [];
      for (let i = this.minYear; i < this.maxYear; i++) {
        years.push(i);
      }
      return years;
    },
  },
};
</script>

<style scoped>
.v-application .primary--text {
  caret-color: #7319d5;
  color: #7319d5 !important;
}
.input-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}
</style>
